// Imports
// ------
import styled, { css } from 'styled-components';
import { Section } from '@tackl';
import { emStyles } from '@type';
import TransitionLink from 'gatsby-plugin-transition-link';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		padding: 6rem 0 6rem 0;
	`
);

export const Content = styled.div(
	(props) => css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 1;
	`
);

export const Post = styled(TransitionLink)(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		transition: ${props.theme.ease};

		&.is-next {
			flex-flow: row-reverse;
			margin-left: auto;
		}

		&.is-prev {
			flex-flow: row;
			margin-right: auto;
		}
	`
);

export const IconJacket = styled.div(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;

		width: 4.8rem;
		height: 4.8rem;
		border: 1px solid ${props.theme.white};
		margin: ${props.isNext ? `0 0 0 2.4rem` : `0 2.4rem 0 0`};
		transform: perspective(500px) rotateY(0deg);
		overflow: hidden;

		svg {
			position: relative;
			z-index: 3;
			transform: rotate(${props.isNext ? 0 : 180}deg);
			width: 1.2rem;
			height: 1.2rem;
			transition: ${props.theme.ease};
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: ${props.theme.ease};
		}

		&:before {
			z-index: 1;
			background: linear-gradient(to right, transparent, white, transparent);
			left: 0;
			right: auto;
			width: 100%;
			transform: translateX(-100%);
		}

		&:after {
			z-index: 2;
			background: ${props.theme.white};
			opacity: 0;
		}

		&:hover {
			transform: perspective(500px) rotateY(-15deg);
			cursor: pointer;

			&:before {
				transform: translateX(100%);
			}
			&:after {
				opacity: 1;
			}

			svg {
				fill: ${props.theme.bc1};
			}
		}
	`
);

export const Label = styled.span(
	(props) => css`
		span {
			&:first-child,
			&:last-child {
				${emStyles}
			}

			&:first-child {
				font-weight: ${props.theme.bold};
			}

			&:last-child {
				font-weight: ${props.theme.reg};
			}
		}
	`
);
