// Imports
// ------
import React from 'react';
import MasonryGrid from './MasonryGrid';
import SingleImage from './SingleImage';
import { Row, Column } from '@waffl';
// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const ProjectBuilder = ({ content }) => (
	<Jacket>
		<Row isExpanded sidePad>
			<Column small={12} mpad>
				{content.map((block, i) => {
					const isLast = i + 1 === content.length;

					if (block.model.name === 'Masonry Image Grid')
						return (
							<MasonryGrid key={i} images={block.images} isLast={isLast} />
						);

					if (block.model.name === 'Single Image')
						return <SingleImage key={i} image={block.image} isLast={isLast} />;
				})}
			</Column>
		</Row>
	</Jacket>
);

export default ProjectBuilder;
