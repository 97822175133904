// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Div, Section, Em } from '@tackl';
import { h3Styles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		padding-top: 6rem;
		background: ${props.theme.black};
	`
);

export const FeaturedMedia = styled(Div)(
	(props) => css`
		position: relative;
		padding-top: 56.25%;
		overflow: hidden;

		${breakup.large`
			margin-left: 1.2rem;
			margin-right: 1.2rem;
		`}
	`
);

export const Content = styled.div(
	(props) => css`
		position: relative;

		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: flex-start;

		padding: 3.6rem 2.4rem;
		background: ${props.theme.bc3};

		${breakup.large`
            display: flex;
            flex-flow: row;
            align-items: stretch;
            justify-content: space-between;

            margin: 0 1.2rem;
            padding: 3.6rem 6rem;
        `}
	`
);

export const Details = styled.div(
	(props) => css`
		width: 100%;
		padding: 0 0 3.6rem 0;
		border-bottom: 1px solid ${props.theme.white};

		${breakup.large`
            width: 50%;
            border-bottom: none;
            border-right: 1px solid ${props.theme.white};
            padding: 0 6rem 0 0;
        `}
	`
);

export const Caption = styled(Em)(
	(props) => css`
		margin-bottom: 2.4rem;

		${breakup.large`
            margin-bottom: 4.8rem;
        `}
	`
);

export const Title = styled.h1(
	(props) => css`
		${h3Styles}
		display: flex;
		flex-flow: column;
		flex: 1;
		align-items: flex-start;
		justify-content: center;

		margin-bottom: 2.4rem;

		${breakup.large`
            height: 14.4rem;
            margin-bottom: 4.8rem;
        `}

		span {
			display: block;
		}
	`
);

export const EditionsYear = styled.div(
	(props) => css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 1;
	`
);

const sharedEmStyles = (props) => css`
	span {
		font-weight: ${props.theme.light};
	}
`;

export const Editions = styled(Em)(
	(props) => css`
		${sharedEmStyles}
	`
);

export const Year = styled(Em)(
	(props) => css`
		${sharedEmStyles}
	`
);

export const Overview = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		padding: 3.6rem 0 0 0;

		${breakup.large`
            width: 50%;
            padding: 0 0 0 6rem;
        `}

		p {
			margin-bottom: 2.4rem;

			&:last-child {
				margin: 0;
			}
		}
	`
);

export const OverviewContent = styled.div(
	(props) => css`
		margin-bottom: 2.4rem;
	`
);

export const Image = styled.div(
	(props) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		overflow: hidden;
		opacity: ${props.videoReady ? 0 : 1};
		pointer-events: ${props.videoReady ? `none` : `all`};
		transition: ${props.theme.ease};

		&:after {
			content: '';
			position: absolute;
			z-index: 3;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			box-sizing: border-box;
			width: 6rem;
			height: 6rem;
			border-style: solid;
			border-width: 3rem 0px 3rem 6rem;

			border-color: transparent transparent transparent white;
		}

		.gatsby-image-wrapper {
			width: 100%;
			height: 100%;

			img {
				user-select: none;
			}
		}
	`
);

export const Video = styled.div(
	(props) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		overflow: hidden;
		width: 100%;

		> div {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	`
);
