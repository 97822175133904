// Imports
// ------
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import HTMLParser from '@parts/HTMLParser';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import {
	Jacket,
	FeaturedMedia,
	Content,
	Details,
	Overview,
	OverviewContent,
	Caption,
	Title,
	EditionsYear,
	Editions,
	Year,
	Image,
	Video,
} from './styles';

// Component
// ------
const Hero = ({ content }) => {
	const { title, overview, category, year, brand, image, video } = content;

	const [videoReady, setVideoReady] = useState(false);

	const handleVideo = () => {
		setVideoReady(true);
	};

	return (
		<Jacket pad>
			<Row isExpanded isCollapsed sidePad>
				<Column>
					<FeaturedMedia marTop>
						<Image onClick={handleVideo} videoReady={videoReady}>
							<GatsbyImage
								image={image.gatsbyImageData}
								alt={image.alt ? image.alt : 'NFT Featured Image'}
							/>
						</Image>

						{video && (
							<Video>
								<ReactPlayer
									url={video.url}
									playing={videoReady}
									controls
									width={`100%`}
									height={`100%`}
								/>
							</Video>
						)}
					</FeaturedMedia>

					<Content>
						<Details>
							<Caption>{category}</Caption>
							<Title>{title}</Title>

							<EditionsYear>
								<Editions weight='semi'>
									Brand: <span>{brand}</span>
								</Editions>
								<Year weight='semi'>
									Year: <span>{year}</span>
								</Year>
							</EditionsYear>
						</Details>

						<Overview>
							<Caption>Overview</Caption>

							<OverviewContent>
								<HTMLParser toParse={overview} />
							</OverviewContent>
						</Overview>
					</Content>
				</Column>
			</Row>
		</Jacket>
	);
};

export default Hero;
