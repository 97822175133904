// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/SeoPost';
import PageTransition from '@parts/PageTransition';
import Hero from '@parts/Hero/Project';
import ProjectBuilder from '@parts/ProjectBuilder';
import BeforeNextProject from '@parts/BeforeNextProject';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function Post({ transitionStatus, entry, exit, data, pageContext }) {
	const bp = useBreakpoint();
	const ts = transitionStatus;

	useEffect(() => {
		const hideNotification = action(() => {
			headerState.notifyActive = false;
			headerState.notifySize = 0;
		});

		hideNotification();

		const timer = setTimeout(
			() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			bp.large ? 500 : 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<>
			<Seo
				data={data.page.seo}
				postTitle={data.page.title}
				backupData={data.globalSeo.seo}
			/>

			<PageTransition status={ts} entry={entry} exit={exit}>
				<Hero content={data.page} />
				<ProjectBuilder content={data.page.pageBuilder} />
				<BeforeNextProject
					prev={pageContext.prevPage}
					next={pageContext.nextPage}
				/>
				<Newsletter />
				<Footer />
			</PageTransition>
		</>
	);
}

export default Post;

// GraphQL
// ------
export const query = graphql`
	query ProjectPostQuery($slug: String!) {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
		page: datoCmsProjectsPost(slug: { eq: $slug }) {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
			title
			overview
			category
			year
			brand
			image: heroImage {
				gatsbyImageData(
					width: 1440
					placeholder: BLURRED
					forceBlurhash: false
					layout: FULL_WIDTH
					backgroundColor: ""
				)
				alt
				smartTags
			}
			video: heroVideo {
				url
			}
			pageBuilder {
				... on DatoCmsSingleImage {
					model {
						name
					}
					image {
						gatsbyImageData(
							width: 1440
							placeholder: BLURRED
							forceBlurhash: false
							layout: FULL_WIDTH
							backgroundColor: ""
						)
						alt
						smartTags
					}
				}

				... on DatoCmsMasonry {
					model {
						name
					}
					images {
						gatsbyImageData(
							width: 720
							placeholder: BLURRED
							forceBlurhash: false
							layout: FULL_WIDTH
							backgroundColor: ""
						)
						alt
						smartTags
					}
				}
			}
		}
	}
`;
